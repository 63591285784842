import React, { Component } from 'react';
import Screenshots from "../../images/screenshots/lims.png";

class Homepagesectiona extends Component {
    render() {
        return (
            <div>
                <section className="section-a" id="section-home-a">
  <div className="container">
		<div className="columns is-marginless">
<div className="column is-half">
<figure className="fullimage">
	<img src={Screenshots} alt="livelab screenshot"/>
</figure>
</div>
		<div className="column is-5 is-offset-1 gentext">
			<p className="beforetitle">Our Software</p>
<h3>Your Productivity Toolkit</h3><hr/>
<p>Cool Harbour develop enterprise software applications and business productivity tools.<br/>
We produce <strong>bespoke products</strong> for clients and have developed numerous custom <strong>Software as a Service</strong> products<br/>
</p>
		</div>
      </div>
  </div>
</section>
            </div>
        );
    }
}

export default Homepagesectiona;