import React, { Component } from 'react';
import "../../style/coolharbour-tech-homepage.css"
import { Link } from "gatsby";
import { Globe, Database, Users } from 'react-feather';
import LottieControl from "./homelottie"


class Mainbannerhomepage extends Component {

    render() {
        return (
            <div>
                <section className="mainbanner">
  <div className="container">
		<div className="columns is-marginless">
		<div className="column is-7">

			<p className="topsubtitle">Customised software systems for the process industry</p>
			<h1>Productivity Issues<br/>Data Retrieval<br/>Process Bottlenecks</h1>
			<p>Eliminate bottlenecks, alleviate productivity issues & solve data retrieval problems</p><br/>
			<Link className="cta-tomato button" to="/about-coolharbour/#contactform">Get a demo</Link>

		</div>
		<div className="column is-5">
			<LottieControl />
		</div>
      </div>
  </div>
</section>
<div className="squareover-lightblue"></div>
<section className="under-mainbanner">
	<div className="threeboxes">
  <div className="container">
  			<div className="columns is-marginless">
		<div className="column is-one-third">
			<div className="boxcard">
				<p class="icono"><Globe /></p>
				<h3>Enterprise-level Software applications</h3>
				<p>Cool Harbour develops enterprise-level code applications and business productivity tools</p>
			</div>
		</div>
				<div className="column is-one-third">
			<div className="boxcard">
			<p class="icono"><Database /></p>
				<h3>Development</h3>
				<p>Over thirty years of experience in software and production for the manufacturing industry at a multi-national level.</p>
			</div>
		</div>
				<div className="column is-one-third">
			<div className="boxcard">
			<p class="icono"><Users /></p>
				<h3>Management</h3>
				<p>Unparalleled project-management experience in global manufacturing, education and software industries</p>
			</div>
		</div>
  </div>
</div>
</div>
</section>
            </div>
        );
    }
}

export default Mainbannerhomepage;