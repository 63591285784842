import React from "react"
import "../style/bulma.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-tech-homepage.css"


import Layout from "../components/layout"
import Mainbannerhomepage from "../components/homepage/mainbannerhomepage"
import Homepagesectiona from "../components/homepage/homepage-section-a"
import Homepagesectionb from "../components/homepage/homepage-section-b"
import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"
import Preloadvid from "../components/extras/preloadvid"

const IndexPage = () => (
  <Layout>
    <SEO title="Bespoke Software tools" />
   <Mainbannerhomepage />
   <Homepagesectiona />
   <Homepagesectionb />
   <Contactform />
   <Coolfooter />
   <Preloadvid />
  </Layout>
)

export default IndexPage
