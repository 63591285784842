import React, { Component } from 'react';
import { Link } from "gatsby";
import Liveviewscreenpic from "../../images/screenshots/liveviewscreen.jpg";
import Packagingscreenpic from "../../images/screenshots/cancompatibility.jpg";
import Datamanagementscreenpic from "../../images/screenshots/datamanagementscreen.jpg";
import Auditscreenpic from "../../images/screenshots/aggregator-screen.jpg";
import Trainingscreenpic from "../../images/screenshots/trainagg-screen.jpg";
import Lmsscreenpic from "../../images/screenshots/lms-screen.jpg";


const styles = {
    liveviewbackpic: {
    backgroundImage: `url(${Liveviewscreenpic})`
    },
    packagingbackpic: {
    backgroundImage: `url(${Packagingscreenpic})`
    },
    datamanagementbackpic: {
    backgroundImage: `url(${Datamanagementscreenpic})`
    },
    auditbackpic: {
      backgroundImage: `url(${Auditscreenpic})`
      },
      trainingbackpic: {
      backgroundImage: `url(${Trainingscreenpic})`
      },
      lmsbackpic: {
      backgroundImage: `url(${Lmsscreenpic})`
      }
};


class Homepagesectionb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vidsrcb: ''
        };
      }

      swapVidSource = () => {
        this.setState({
            vidsrcb: '/storage/coolharbour/vid/chemistryvid.mp4'
            // vidsrcb: '/vid/chemistryvid.mp4'
          });
}

playVid = () => {
    if(this.refs.vidRefb){
      this.refs.vidRefb.play();
      }
}

pauseVideo = () => {
  if(this.refs.vidRefb){
    this.refs.vidRefb.pause();
    }
}

componentDidMount = () => {
    this.swapVidSource();
    setTimeout(() => {
        this.playVid();
      }, 1000);
  };

  componentWillUnmount = () => {
      this.pauseVideo();
  };

    render() {

        return (
            <div>
<section className="sectionpadding section-c">
<div className="container">
  <div className="columns is-marginless is-multiline">
    <div className="column is-half gentext-white">
            <p className="beforetitle">Custom Built Software</p>
<h3>Our Solutions</h3><hr/>
    </div>
  </div>
</div>


<div className="container">
  <div className="columns is-marginless is-multiline">

          <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
          <figure className="image backhammer" style={styles.liveviewbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/liveviewscreen.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h4>Liveview</h4>
            <ul>
            <li>On-line assessment tool for classroom training</li>
            <li>Live view of test results per question per participant</li>
            <li>Immediate score delivered to the participant</li>
            </ul>
            <br/>
            <Link className="tag is-primary" to="/liveview/">Read More</Link>
          </div>
        </div>
      </div>
    </div>

          <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
          <figure className="image backhammer" style={styles.packagingbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/cancompatibility.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
              <h4>Packaging Process</h4>
              <p>Web-based application portal enabling client and its suppliers to manage:</p>
              <ul><li>The process of requesting</li>
              <li>Testing</li>
              <li>Progress</li>
              <li>And maintaining supplier approval/certification for
              packaging designs</li>
              </ul>
<br/>
            <Link className="tag is-primary" to="/packagingprocess/">Read More</Link>
          </div>
        </div>
      </div>
    </div>

          <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
          <figure className="image backhammer" style={styles.datamanagementbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/datamanagementscreen.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h4>Data Management Portal</h4>
            <p>Web-based application portal that facilitates</p>

<li>A centralized, single location for data, information
and knowledge</li>
<ul><li>Process based actions</li>
<li>Identity and access management</li>
<li>Auto notification to improve productivity</li>
<li>Full traceability of each action</li>
</ul>
            <Link className="tag is-primary" to="/data-management-portal/">Read More</Link>
          </div>
        </div>
      </div>
    </div>

          {/* <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
        <figure className="image backhammer" style={styles.auditbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/aggregator-screen.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h4>Auditor Aggregator</h4>

            <p>Web-based application portal that facilitates</p>
<ul>
  <li>A centralized, single location for data, information
and knowledge</li>
<li>Process based actions</li>
<li>Identity and access management</li>
<li>Auto notification to improve productivity</li>
<li>Full traceability of each action</li>
</ul>
            <br/>
            <Link className="tag is-primary" to="/auditor-aggregator/">Read More</Link>
          </div>
        </div>
      </div>
    </div> */}

          {/* <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
        <figure className="image backhammer" style={styles.trainingbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/trainagg-screen.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h4>Training Aggregator</h4>
            <p>Web-based application portal that facilitates</p>
  <ul>
  <li>A centralized, single location for trainers and
  training bodies</li>
  <li>Direct booking process</li>
  <li>Booking process</li>
  <li>Auto notification to improve productivity</li>
  <li>Full traceability of each action</li>
  </ul>
            <br/>
            <Link className="tag is-primary" to="/training-aggregator/">Read More</Link>
          </div>
        </div>
      </div>
    </div> */}

    {/* <div className="column is-one-third posrelzee flexoheights">
      <div className="card hotcard">
        <div className="card-image">
        <figure className="image backhammer" style={styles.lmsbackpic}>
            <img alt="Cool harbour" className="hideo screensame" src={`screenshots/lms-screen.jpg`}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <h4>Learning Management System</h4>
            <p>The web-based application portal enables the clients to centralise and manage learning in their organisation</p>
<p>The portal has the following features:</p>
<ul><li>User/Learner management </li><li>Group management</li><li>Module management</li></ul>
            <Link className="tag is-primary" to="/learning-management-system/">Read More</Link>
          </div>
        </div>
      </div>
    </div> */}

  </div>
  </div>
  <div className="squareover-white"></div>
</section>

<div className="underitems showondesktop">
<div className="overfunk"></div>
<video ref="vidRefb" autoplay muted loop id="undervideo" key={this.state.vidsrcb}>
<source src={this.state.vidsrcb} type="video/mp4"/>
</video>
<div className="container">
  <div className="columns is-marginless">
  <div className="column is-8">
<p>Cool Harbour creates <span>tailored process management tools</span> to augment your manufacturing systems. <span>Connect with us today!</span></p>
  </div>
    </div>
</div>
</div>
    </div>
        );
    }
}

export default Homepagesectionb;