import React, { Component } from 'react';

class Preloadvid extends Component {
    render() {
        return (
            <div>
                <div id="preloadvideo">
<video muted loop="false" autostart="false">
<source data-src="" src="vid/classroom.mp4" type="video/mp4" />
</video>
</div>
            </div>
        );
    }
}

export default Preloadvid;