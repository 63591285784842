import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from "../../plugins/lottie/data.json"
 
export default class LottieControl extends React.Component {
 
  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        speed: 0.15,
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 
    return <div>
      <Lottie options={defaultOptions}/>
    </div>
  }
}